<template src="./score-chart-detail.html"></template>

<script>
import appHeader from "../../shared-module/components/app-header/app-header";
import appFooter from "../../shared-module/components/app-footer/app-footer.vue";
import appsidebar from "../../shared-module/components/app-sidebar/app-sidebar.vue";
import QuizService from "../../quiz-module/services/quiz-service";
import customStorageService from "../../app-services/custom-storage-service";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "score-predictor-list",
  data() {
    return {
      isLoading: false,
      user: customStorageService.getUser(),
      mainTagStats: {},
      course_tag: this.$route.params.course_tag,
      series: [70],
      pendingTags: [],
      strength: [],
      weakness: [],
      chartWeakness: {
        grid: {
          padding: {
            top: -15,
            left:-15,
            right:-15,
            bottom: -15
          }
        },
        chart: {
          height: 116,
          type: "radialBar"
        },
        colors: [""],
        plotOptions: {
          radialBar: {
            hollow: {
              size: "60%",
              // margin:0,
            },
            track: {
              show: true,
              background: "#E3E8F1",
              strokeWidth: "100%",
              margin: 0 // margin is in pixels
            },
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: 0,
                show: false,
                color: "#333",
                fontSize: "12px",
                fontWeight: 400
              },
              value: {
                color: "#000",
                fontSize: "40px",
                fontWeight: 800,
                show: true,
                offsetY: 15
              }
            }
          }
        },
        fill: {
          type: "solid",
          colors: ["#e03249"]
        },
        stroke: {
          lineCap: "round"
        }
      },
      chartStrength: {
        grid: {
          padding: {
            top: -15,
            left:-15,
            right:-15,
            bottom: -15
          }
        },
        chart: {
          // height: 140,
          type: "radialBar"
        },
        colors: [""],
        plotOptions: {
          radialBar: {
            hollow: {
              size: "60%"
            },
            track: {
              show: true,
              background: "#E3E8F1",
              strokeWidth: "100%",
              margin: 0 // margin is in pixels
            },
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: 0,
                show: false,
                color: "#333",
                fontSize: "12px",
                fontWeight: 400
              },
              value: {
                color: "#000",
                fontSize: "40px",
                fontWeight: 800,
                show: true,
                offsetY: 15
              }
            }
          }
        },
        fill: {
          type: "solid",
          colors: ["#22BC64"]
        },
        stroke: {
          lineCap: "round"
        }
      },
    };
  },
  components: {
    "app-header": appHeader,
    "app-footer": appFooter,
    "app-sidebar": appsidebar,
    apexchart: VueApexCharts,
  },
  mounted() {
    this.getUserStrengthWeakness();
  },
  destroyed() {},
  methods: {
    async getUserStrengthWeakness() {
      this.isLoading = true;
      const res = await QuizService.getUserSW({
        type: "module",
        tag_name: this.course_tag,
        user_id: this.$route.query.user_id ? this.$route.query.user_id : this.user.id,
      });

      if(res.data && res.data.success) {
        this.mainTagStats = res.data.mainTagStats;
        this.pendingTags = res.data.pendingTags;
        res.data.swStats.forEach(stats => {
          if(stats.score_percentage >= 80) {
            this.strength.push(stats);
          } else {
            this.weakness.push(stats);
          }
        });
      }
      console.log(this.$store.state.isIframe)
      this.isLoading = false;
      console.log('page-height-' + document.documentElement.scrollHeight);
    },
    async gotoCourseDetail(tag) {
      const res = await QuizService.getTagCourse({
        tag_name: tag,
      });
      if(res.data && res.data.success) {
        const course = res.data.course;
        this.$router.push(`/course/course-detail/${course.index_url}/${course.id}?module_id=${course.module_id}`);

        console.log(`page-url-change|https://velocity.nearpeer.org/course/course-detail/${course.index_url}/${course.id}`)
      }
    }
  }
};
</script>

<style src="./score-chart-detail.css" scoped></style>
